import axios from 'axios';
import { isEmpty } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { startLoadUser } from '../actions/auth';
import { startLoadWorkspaces } from '../actions/workspace';
import LoadingPage from '../components/LoadingPage';

import { LS_USER_KEY, LS_WORKSPACE_KEY } from '../const/local-storage-keys';

interface PrivateRouteProps {
  children: ReactElement;
}

const PrivateRoute = ({ children } : PrivateRouteProps) => {
    const {
        auth: user,
        workspace: { workspaces, ...workspace },
    } = useSelector((state: any) => state);

    const { pathname } = useLocation();
    const dispatch = useDispatch<any>();

    useEffect(() => {
        const userLs = localStorage.getItem(LS_USER_KEY);
        const token = userLs && JSON.parse(userLs).token;
        if (token) {
            dispatch(startLoadUser(token));
        }
    }, [dispatch]);

    useEffect(() => {
        if (!user._id) return;

        axios.defaults.headers.common.authorization = `Bearer ${user.token}`;
        localStorage.setItem(LS_USER_KEY, JSON.stringify(user));
        dispatch(startLoadWorkspaces());
    }, [user, dispatch]);

    useEffect(() => {
        if (isEmpty(workspace)) return;
        localStorage.setItem(LS_WORKSPACE_KEY, JSON.stringify(workspace));
    }, [workspace, dispatch]);

    if (!user._id) {
        return <Navigate to="/login" />;
    } if (!user.mobile) {
        return <Navigate to="/signup" />;
    } if (user.mustAnswerQuestion && pathname !== '/onboarding/create-workspace') {
        return <Navigate to="/onboarding/create-workspace" />;
    } if (!workspaces) {
        return <LoadingPage />;
    } if (
        workspaces.length === 0
    && !pathname.startsWith('/onboarding')
    && !workspace.active?._id
    && !user.mustAnswerQuestion
    ) {
        console.log('HERE 5');
        return <Navigate to="/onboarding/create-workspace" />;
    }

    return children;
};

export default PrivateRoute;
